<template>
  <div>
    <h1>Thêm quảng cáo</h1>
    <v-row class="align-center justify-center ma-0 pt-5">
      <v-col cols="12">
        <h2>
          Bài viết
          <v-btn icon @click="addBlockNews">
           <v-icon color="primary">mdi-plus-box</v-icon>
          </v-btn>
        </h2>
        <v-row>
          <v-col cols="12">
            <div v-if="ads_news && ads_news.length > 0">
              <v-row class="pa-0 ma-0" v-for="(item, index) in ads_news" :key="index">
                <v-col cols="6" >
                  <v-textarea
                      background-color="#EDF2F7"
                      filled
                      v-model="item.scripts"
                      label="Thêm script"
                      rows="1"
                  ></v-textarea>
                </v-col>
                <v-col cols="4" >
                  <v-select
                      background-color="#EDF2F7"
                      filled
                      :items="position_news"
                      item-text="name"
                      item-value="value"
                      v-model="item.position"
                      label="Chọn vị trí"
                  ></v-select>
                </v-col>
                <v-col cols="2" class="">
                  <v-btn
                      class="ma-2"
                      outlined
                      large
                      color="indigo"
                      @click="clearBlock(index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <h2>
          Chuyên mục
          <v-btn icon @click="addBlockCategory">
            <v-icon color="primary">mdi-plus-box</v-icon>
          </v-btn>
        </h2>
        <v-row v-if="ads_category && ads_category.length >0">
          <v-col cols="12">
            <div>
              <v-row class="pa-0 ma-0" v-for="(item, index) in ads_category" :key="index">
                <v-col cols="6" >
                  <v-textarea
                      background-color="#EDF2F7"
                      filled
                      v-model="item.scripts"
                      label="Thêm script"
                      rows="1"
                  ></v-textarea>
                </v-col>
                <v-col cols="4" >
                  <v-select
                      background-color="#EDF2F7"
                      filled
                      :items="position_category"
                      item-text="name"
                      item-value="value"
                      v-model="item.position"
                      label="Chọn vị trí"
                  ></v-select>
                </v-col>
                <v-col cols="2" class="">
                  <v-btn
                      class="ma-2"
                      outlined
                      large
                      color="indigo"
                      @click="clearBlockCategory(index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <h2>
          Hỏi đáp
          <v-btn icon @click="addBlockQuestion">
            <v-icon color="primary">mdi-plus-box</v-icon>
          </v-btn>
        </h2>
        <v-row v-if="ads_question && ads_question.length > 0">
          <v-col cols="12">
            <div>
              <v-row class="pa-0 ma-0" v-for="(item, index) in ads_question" :key="index">
                <v-col cols="6" >
                  <v-textarea
                      background-color="#EDF2F7"
                      filled
                      v-model="item.scripts"
                      label="Thêm script"
                      rows="1"
                  ></v-textarea>
                </v-col>
                <v-col cols="4" >
                  <v-select
                      background-color="#EDF2F7"
                      filled
                      :items="position_question"
                      item-text="name"
                      item-value="value"
                      v-model="item.position"
                      label="Chọn vị trí"
                  ></v-select>
                </v-col>
                <v-col cols="2" class="">
                  <v-btn
                      class="ma-2"
                      outlined
                      large
                      color="indigo"
                      @click="clearBlockQuestion(index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <h2>
          Banner
          <v-btn icon @click="addBlockBanner">
            <v-icon color="primary">mdi-plus-box</v-icon>
          </v-btn>
        </h2>
        <v-row v-if="ads_banner && ads_banner.length > 0">
          <v-col cols="12">
            <div>
              <v-row class="pa-0 ma-0" v-for="(item, index) in ads_banner" :key="index">
                <v-col cols="6" >
                  <v-textarea
                      background-color="#EDF2F7"
                      filled
                      v-model="item.scripts"
                      label="Thêm script"
                      rows="1"
                  ></v-textarea>
                </v-col>
                <v-col cols="4" >
                  <v-select
                      background-color="#EDF2F7"
                      filled
                      :items="position_banner"
                      item-text="name"
                      item-value="value"
                      v-model="item.position"
                      label="Chọn vị trí"
                  ></v-select>
                </v-col>
                <v-col cols="2" class="">
                  <v-btn
                      class="ma-2"
                      outlined
                      large
                      color="indigo"
                      @click="clearBlockBanner(index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="mb-5">
      <h2>Shopee</h2>
      <div>
        <v-file-input
            accept="image/*"
            prepend-icon="mdi-image"
            label="Chọn ảnh"
            v-model="file"
            @change="imgEvent"
        >
        </v-file-input>
        <div class="w-100">
          <img :src="image" alt="" style="object-fit: contain">
        </div>
        <v-text-field
            class="w-50 ml-8"
          v-model="link_shopee"
          label="Nhập link shopee"
        ></v-text-field>
      </div>
    </div>
    <div>
      <h2>ads.txt</h2>
      <v-textarea
          background-color="#EDF2F7"
          filled
          label="Thêm script"
          rows="10"
          v-model="ads_txt"
      ></v-textarea>
    </div>
    <div>
      <h2>HEAD</h2>
      <v-textarea
          background-color="#EDF2F7"
          filled
          label="Thêm script"
          rows="10"
          v-model="ads_head"
      ></v-textarea>
    </div>
    <v-btn color="primary" @click="save"
    >Save
    </v-btn
    >
  </div>
</template>
<script>
import {SAVE_ADS, GET_SCRIPT_ADS} from '@/store/setting.module'
import {mapGetters} from  'vuex'
export default {
  data(){
    return{
      file: {},
      link_shopee:'',
      position_news: [
        {
          name:'Đầu bài viết',
          value:'top'
        },
        {
          name:'Giữa bài viết',
          value:'middle'
        },
        {
          name:"Cuối cùng bài viết",
          value:'bottom'
        },
        {
         name:'Dưới các tiêu đề h2',
         value:'under_h2'
        }
      ],
      position_category: [
        {
          name:'Đầu',
          value:'top'
        },
        {
          name:"Cuối",
          value:'bottom'
        }
      ],
      position_question: [
        {
          name:'Dưới tên bài',
          value:'under_title'
        },
        {
          name:"Dưới câu hỏi",
          value:'under_question'
        },
        {
          name:"Dưới trả lời",
          value:'under_btn_answer'
        }
      ],
      position_banner: [
        {
          name:'Trái',
          value:'left'
        },
        {
          name:"Phải",
          value:'right'
        }
      ],
      ads_news: [],
      ads_category: [],
      ads_question: [],
      ads_banner: [],
      ads_txt: '',
      ads_head:'',
      ads_shopee: {},
      image:'',
    }
  },
  components:{
  },

  computed:{
    ...mapGetters({
      ads: 'ads'
    })
  },
  async created() {
    await this.$store.dispatch(GET_SCRIPT_ADS)
  },

  watch:{
    ads(newVal) {
      if(newVal) {
        if (newVal.news) {
          this.ads_news = [...newVal.news]
        }
        if (newVal.category) {
          this.ads_category = [...newVal.category]
        }
        if (newVal.question) {
          this.ads_question = [...newVal.question]
        }
        if (newVal.banner) {
          this.ads_banner = [...newVal.banner]
        }
        if (newVal.head) {
          this.ads_head = newVal.head
        }
        if (newVal.txt) {
          this.ads_txt = newVal.txt
        }
        if (newVal.shopee) {
          this.ads_shopee = JSON.parse(newVal.shopee)
          this.image = this.ads_shopee.src
          this.link_shopee = this.ads_shopee.link
        }
      }
    }
  },

  methods:{
    imgEvent(e){
      if(e){
        this.image = URL.createObjectURL(e);
      } else {
        this.image = ''
      }
    },
    //news
    clearBlock(index){
      this.ads_news.splice(index, 1)
      this.$forceUpdate()
    },
    addBlockNews(){
      this.ads_news.push({
        scripts: '',
        position: null,
      })
    },
    //category
    clearBlockCategory(index){
      this.ads_category.splice(index, 1)
      this.$forceUpdate()
    },
    addBlockCategory(){
      this.ads_category.push({
        scripts: '',
        position: null,
      })
    },
    //question
    clearBlockQuestion(index){
      this.ads_question.splice(index, 1)
      this.$forceUpdate()
    },
    addBlockQuestion(){
      this.ads_question.push({
        scripts: '',
        position: null,
      })
    },
    //Banner
    clearBlockBanner(index){
      this.ads_banner.splice(index, 1)
      this.$forceUpdate()
    },
    addBlockBanner(){
      this.ads_banner.push({
        scripts: '',
        position: null,
      })
    },
    async  save(){
      let formData = new FormData();
      let arr_data = [
        {
          key: 'body',
          position: 'news',
          scripts: JSON.stringify(this.ads_news)
        },
        {
          key: 'body',
          position: 'category',
          scripts: JSON.stringify(this.ads_category)
        },
        {
          key: 'body',
          position: 'question',
          scripts: JSON.stringify(this.ads_question)
        },
        {
          key: 'body',
          position: 'banner',
          scripts: JSON.stringify(this.ads_banner)
        },
        {
          key: 'head',
          position: '',
          scripts: this.ads_head
        },
        {
          key: 'txt',
          position: '',
          scripts: this.ads_txt
        },
        {
          key: 'shopee',
          position: '',
        },
      ]
      formData.append("data", JSON.stringify(arr_data));
      formData.append("file", this.file);
      formData.append("link", this.link_shopee);
      await this.$store.dispatch(SAVE_ADS, formData).then(data=> {
        if (data) {
          this.$toasted.success('Lưu thành công!!',{
            position :'top-right',
            duration : 3000
          })
        }
      });
    }
  }
}
</script>